import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SharedService {
  public message = new BehaviorSubject("");
  sharedMessage = this.message.asObservable();

  public exposer = new BehaviorSubject("");
  sharedExposer = this.exposer.asObservable();

  public betSlipData = new BehaviorSubject("");
  sharedbetSlipData = this.betSlipData.asObservable();

  public betSlipRes = new BehaviorSubject("");
  sharedbetSlipRes = this.betSlipRes.asObservable();

  public tabCasino = new BehaviorSubject("");
  sharedTabCasino = this.tabCasino.asObservable();

  public tabCasinoI = new BehaviorSubject("");
  sharedTabCasinoI = this.tabCasinoI.asObservable();

  public updateExpoPusher = new BehaviorSubject("");
  sharedupdateExpoPusher = this.updateExpoPusher.asObservable();

  constructor() {}

  nextMessage(message: any) {
    this.message.next(message);
  }

  emitExposer(data: any) {
    this.exposer.next(data);
  }

  emitbetSlipData(data: any) {
    this.betSlipData.next(data);
  }

  emitbetSlipRes(data: any) {
    this.betSlipRes.next(data);
  }

  emitTabCasino(data: any) {
    this.tabCasino.next(data);
  }

  emitTabCasinoI(data: any) {
    this.tabCasinoI.next(data);
  }

  emitUpdateExpoPusher(data: any) {
    this.updateExpoPusher.next(data);
  }
}
